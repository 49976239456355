import axios from 'axios';
import process from 'process';

window.process = process;
const api = axios.create({
  baseURL: process.env.VITE_API_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Remover token e redirecionar para login
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

api.interceptors.response.use((response) => {
  const newToken = response.headers['x-new-token'];
  if (newToken) {
    localStorage.setItem('accessToken', newToken);
  }
  return response;
});

export default api;
