
import React, { useCallback, useState } from 'react';
import ReactFlow, {
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  Background,
  Controls,
} from 'reactflow';
import 'reactflow/dist/style.css';

const initialNodes = [
  { id: '1', type: 'input', data: { label: 'Start Trigger' }, position: { x: 250, y: 0 } },
];

const initialEdges = [];

const availablePieces = [
  { id: 'slack', label: 'Slack (Send Message)' },
  { id: 'googleSheets', label: 'Google Sheets (Append Row)' },
  { id: 'mailchimp', label: 'Mailchimp (Add Contact)' },
];

const WorkflowsPage = () => {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [selectedNode, setSelectedNode] = useState(null);

  const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), []);
  const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);
  const onConnect = useCallback((connection) => setEdges((eds) => addEdge(connection, eds)), []);

  const addPieceToEditor = (piece) => {
    const newNode = {
      id: `${nodes.length + 1}`,
      type: 'default',
      data: { label: piece.label },
      position: { x: Math.random() * 400 + 100, y: Math.random() * 400 + 100 },
    };
    setNodes((nds) => [...nds, newNode]);
  };

  const onNodeClick = useCallback((event, node) => {
    setSelectedNode(node);
  }, []);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <aside style={{ width: '200px', background: '#f0f0f0', padding: '10px' }}>
        <h3>Available Pieces</h3>
        <ul>
          {availablePieces.map((piece) => (
            <li key={piece.id}>
              <button onClick={() => addPieceToEditor(piece)}>{piece.label}</button>
            </li>
          ))}
        </ul>
      </aside>
      <div style={{ flexGrow: 1, position: 'relative' }}>
        <h2 style={{ padding: '10px', margin: 0, backgroundColor: '#282c34', color: 'white' }}>
          Workflow Editor
        </h2>
        {selectedNode && (
          <div style={{ padding: '10px', background: 'white', border: '1px solid black', position: 'absolute', top: 10, right: 10 }}>
            <h4>Configure Node: {selectedNode.data.label}</h4>
            <input
              type="text"
              value={selectedNode.data.label}
              onChange={(e) =>
                setNodes((nds) =>
                  nds.map((n) =>
                    n.id === selectedNode.id ? { ...n, data: { ...n.data, label: e.target.value } } : n
                  )
                )
              }
            />
          </div>
        )}
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeClick={onNodeClick}
          fitView
        >
          <Background />
          <Controls />
        </ReactFlow>
      </div>
    </div>
  );
};

export default WorkflowsPage;
