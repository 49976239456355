import React, { useState } from 'react';
import { Play, RefreshCw, CheckCircle2, XCircle, AlertTriangle, ChevronDown, ChevronUp } from 'lucide-react';
import api from '../../lib/api';
import { useKommoIntegration } from '../../hooks/useKommoIntegration';

interface TestResult {
  name: string;
  status: 'success' | 'error' | 'warning';
  message: string;
  duration: number;
  details?: any;
}

export default function KommoTestingPage() {
  const { isConnected, config, refresh } = useKommoIntegration();
  const [results, setResults] = useState<TestResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedResult, setExpandedResult] = useState<number | null>(null);

  const runTests = async () => {
    setIsLoading(true);
    setResults([]);
    setExpandedResult(null);

    try {
      const startTime = Date.now();

      // Test 1: Configuration Validation
      const configResult: TestResult = {
        name: 'Configuration Validation',
        status: config ? 'success' : 'error',
        message: config
          ? 'Configuration is valid and token is present.'
          : 'Configuration is missing or invalid.',
        duration: 0,
        details: {
          accountDomain: config?.accountDomain || 'Not configured',
          clientId: config?.clientId || 'Not configured',
          connectedAt: config?.connectedAt || 'Never',
        },
      };

      // Test 2: Fetch Today’s Leads
      let leadsResult: TestResult;
      try {
        const leadsStartTime = Date.now();
        const { data: response } = await api.get('/kommo/leads/today');
        const leadsDuration = Date.now() - leadsStartTime;

        leadsResult = {
          name: 'Fetch Today’s Leads',
          status: response.status === 'success' ? 'success' : 'error',
          message: response.status === 'success'
            ? `Found ${response.data?.length || 0} leads created today.`
            : 'Failed to fetch today’s leads.',
          duration: leadsDuration,
          details: response.data || {},
        };
      } catch (error: any) {
        leadsResult = {
          name: 'Fetch Today’s Leads',
          status: 'error',
          message: error.response?.data?.message || 'Failed to fetch today’s leads.',
          duration: 0,
          details: {
            error: error.message,
          },
        };
      }

      // Test 3: API Health Check
      let healthResult: TestResult;
      try {
        const healthStartTime = Date.now();
        const { data: response } = await api.get('/kommo/health');
        const healthDuration = Date.now() - healthStartTime;

        healthResult = {
          name: 'API Health Check',
          status: response.status === 'success' ? 'success' : 'error',
          message: response.status === 'success'
            ? 'API is healthy and responding.'
            : 'API health check failed.',
          duration: healthDuration,
          details: response.data || {},
        };
      } catch (error: any) {
        healthResult = {
          name: 'API Health Check',
          status: 'error',
          message: error.response?.data?.message || 'API health check failed.',
          duration: 0,
          details: {
            error: error.message,
          },
        };
      }

      const totalDuration = Date.now() - startTime;
      configResult.duration = totalDuration;

      setResults([configResult, leadsResult, healthResult]);
    } catch (error: any) {
      console.error('Test execution error:', error);
      setResults([
        {
          name: 'Test Suite',
          status: 'error',
          message: 'Failed to execute tests.',
          duration: 0,
          details: error.response?.data || {},
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleResultDetails = (index: number) => {
    setExpandedResult(expandedResult === index ? null : index);
  };

  if (!isConnected) {
    return (
      <div className="p-6">
        <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
          <div className="flex">
            <AlertTriangle className="h-5 w-5 text-yellow-400" />
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Not Connected</h3>
              <p className="mt-2 text-sm text-yellow-700">
                Please connect to Kommo CRM before running tests.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Kommo Integration Tests</h1>
          <p className="text-gray-500">Test your Kommo CRM integration endpoints</p>
        </div>
        <button
          onClick={runTests}
          disabled={isLoading}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
        >
          {isLoading ? (
            <>
              <RefreshCw className="animate-spin -ml-1 mr-2 h-5 w-5" />
              Running Tests...
            </>
          ) : (
            <>
              <Play className="-ml-1 mr-2 h-5 w-5" />
              Run Tests
            </>
          )}
        </button>
      </div>

      {results.length > 0 && (
        <div className="bg-white rounded-xl shadow-sm divide-y divide-gray-200">
          {results.map((result, index) => (
            <div key={index} className="p-6">
              <button
                onClick={() => toggleResultDetails(index)}
                className="w-full flex items-center justify-between focus:outline-none"
              >
                <div className="flex items-center">
                  {result.status === 'success' && <CheckCircle2 className="h-5 w-5 text-green-500 mr-3" />}
                  {result.status === 'error' && <XCircle className="h-5 w-5 text-red-500 mr-3" />}
                  {result.status === 'warning' && <AlertTriangle className="h-5 w-5 text-yellow-500 mr-3" />}
                  <div className="text-left">
                    <h3 className="text-sm font-medium text-gray-900">{result.name}</h3>
                    <p className="text-sm text-gray-500">{result.message}</p>
                  </div>
                </div>
                <div className="flex items-center ml-4">
                  <span className="text-sm text-gray-500 mr-2">{result.duration}ms</span>
                  {expandedResult === index ? (
                    <ChevronUp className="h-5 w-5 text-gray-400" />
                  ) : (
                    <ChevronDown className="h-5 w-5 text-gray-400" />
                  )}
                </div>
              </button>
              {expandedResult === index && result.details && (
                <div className="mt-4">
                  <pre className="text-xs bg-gray-50 rounded-lg p-4 overflow-x-auto">
                    {JSON.stringify(result.details, null, 2)}
                  </pre>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
