export const PIPELINE_STATUS = {
    LEADS_ENTRADA: 66694519,
    PRIMEIRO_CONTATO: 66694522,
    CONEXAO_ESTABELECIDA: 66694525,
    QUALIFICACAO: 66694528,
    APRESENTACAO: 66694531,
    PROPOSTA: 66694534,
    FECHAMENTO: 66694537,
    VENDA_REALIZADA: 66694540,
    POS_VENDAS: 66694543,
    PERDIDO: 66694546
  };
  
  export const SALE_STATUSES = [
    PIPELINE_STATUS.VENDA_REALIZADA,
    PIPELINE_STATUS.POS_VENDAS,
    PIPELINE_STATUS.FECHAMENTO
  ];